import { Button, Card } from '@mui/material'
import styled from 'styled-components'
export const MainCard = styled(Card)`
	background-color:white !important;
    // box-shadow: -5px 2px 38px 0px rgb(218 218 218 / 75%)  !important;
    box-shadow: 0px 0px 10px 0px rgb(170 167 167 / 72%) !important;
    border-radius: 5px !important;
    margin-top:40px;
    width:100% !important;
    padding:10px 15px;
    overflow:unset !important;
`   
export const MainCardBorder = styled(Card)`
margin-top:40px;
width: 70% !important;
padding:10px 15px;
overflow:unset !important;
border-left:5px solid red;
border-radius:5px 50px 5px 50px !important;
box-shadow:rgb(170 167 167 / 72%) 3px 5px 15px 0px !important;
`   
export const DarkButton = styled(Button)`
    background-color:var(--black) !important;
    color:var(--white) !important;
    white-space:nowrap !important;
    padding:6px !important;
`
export const LightButton = styled(Button)`
    background-color:var(--light-button) !important;
`
export const Bordered = styled(Button)`
    border:1px solid var(--black) !important;
    color:var(--black) !important;
    /* margin-left:20px !important;  */
`
export const BorderedCancel = styled(Button)`
    border:1px solid var(--active) !important;
    color:var(--active) !important;
    padding: 5px 6px !important

`
