import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NewSidebar from '../NewSidebar/NewSidebar';

class PrivateRoute extends Component {
  render() {
	const { component: Component, auth, allowedRoles, hasSidebar, ...rest } = this.props;
	return (
		<Route
		{...rest}
		render={(props) =>
			!auth?.isAuthenticated ? 
				<Redirect to='/login'/>
			: 
			allowedRoles?.includes(auth?.userRole) ?
				hasSidebar ?
					<NewSidebar>
						<Component {...props} />
					</NewSidebar>
				:
					<Component {...props} />
			:
				<Redirect to='/login'/>
			}
		/>
	)
  }
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
})

export default connect(mapStateToProps)(PrivateRoute)