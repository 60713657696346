import React, { Component } from 'react'
import { connect } from 'react-redux'
import './style.css'
export class CompanyLogo extends Component {
  render() {
    const {tenantName,logo} = this.props.authData
    return (
      <div className='company_logo'>
        <img  src={logo} alt="Logo" className="logo logo-sidebar" />
      <h3 className='company_name'>{tenantName}</h3>
  </div>
    )
  }
}

const mapStateToProps = (state) => ({
	authData: state.authReducer,
})
export default connect(mapStateToProps, null)(CompanyLogo)