import { AppBar, TextField } from '@mui/material'
import styled from 'styled-components'
export const MenuMain = styled.div`
.iconavtar 
{
    padding: 15px !important;
}
.iconavtar .MuiAvatar-root
{
    background: red !important;
}
`