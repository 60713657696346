import React from 'react'
import { connect } from 'react-redux'
import Routes from './Routes'
import { tenantWhiteLabel } from '../src/redux/actions/authActions'
class App extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			isTenantLogin: null
		}
	}

	componentDidMount () {
		this.props.tenantWhiteLabel();
	}
	render() {
		return (
			<Routes/>
		)
	}
}

const mapStateToProps = (state) => ({
	alert: state.alertReducer,
	authData: state.authReducer,
})

export default connect(mapStateToProps, { tenantWhiteLabel })(App)
